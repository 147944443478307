
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fastsend-frontend-primary: mat.define-palette(mat.$indigo-palette);
$fastsend-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fastsend-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fastsend-frontend-theme: mat.define-light-theme((
  color: (
    primary: $fastsend-frontend-primary,
    accent: $fastsend-frontend-accent,
    warn: $fastsend-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fastsend-frontend-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap-icons/font/bootstrap-icons.css";


@font-face {
    font-family: Proxima-regular;
    src: url(./assets/fonts/Proxima_Soft_Regular.otf);
  }

@font-face {
    font-family: Proxima-bold;
    src: url(./assets/fonts/Proxima_Soft_Bold.otf);
  }

@font-face {
    font-family: Trueno;
    src: url(./assets/fonts/Trueno_Round_It.otf);
  }

* {
	margin: 0px;
	padding: 0px;
  font-family: Proxima-regular;
}

.fastsend-btn {
    background-color: #F1652F;
    color: white;
    border-radius: 20px;
    border: none;
}

.min-width {
  min-width: 800px !important;
  overflow: auto;
}

.page-title {
  margin-bottom: 20px;
}

.back-button {
  border-radius: 4px;
  background:  #224292;
  padding: 5px 12px;
  border: none;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}


// style for tables
.table-container {
  width: 100%;
  min-width: 500px;
  height: 400px;
  overflow: auto;
  box-shadow: none;
  border-radius: 8px;
  background-color: white;
  box-shadow: 1px 1px 4px 0px rgba(34, 66, 146, 0.10);
}


.empty-state .mat-cell {
  margin: auto;
  text-align: center;
  height: 300px;

  p {
    font-family: Proxima-bold;
    color: #BDBDBD;
    margin-block: 10px;
  }
}
//end of table style

ul li {
  list-style: none;
}
